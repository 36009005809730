.service-container {
    display: inline-block;
    width: 100%;
    font-family: "Montserrat";
    font-size: 13px;
    font-weight: 700;
    vertical-align: middle;
    margin-top: 10px;
    margin-bottom: 10px;
}

.service-title {
    text-align: left;
    color: #3A3A3A;
    float: left;
    max-width: 50%;
}

.service-cost-total {
    max-width: 50%;
    float: right;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: flex-end;
}

.service-cost {
    text-align: right;
    color: #3A3A3A;
}

div .service-button {
    text-align: center;
    margin-left: 15px;
}

.hr-service {
    background: #EAEAEA;
}

.service-button button {
    width: 150px;
    height: 100%;
    border: 0px;
    z-index: 20;
    background: linear-gradient(0.25turn, #1A73E8, #5d9cef, #1A73E8, #1A73E8);
    color: white;
    border-radius: 10px;
}

.category-header {
    color: #303436;
    font-weight: 400;
}

.hr-category {
    background-image: linear-gradient(90deg, white, #1A73E8, white);
}

.category-title {
    font-weight: 800;
    color: black;
    font-family: "Montserrat";
    font-size: 13px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.something {
    border: var(--is-dark-theme,2px) solid black;
}

.menu-field-title {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    border: 0;
    border-radius: 15px;
}

.open-field-title {
    background-color: white;
}

.closed-field-title {
    background: linear-gradient(0.25turn, #1A73E8, #5d9cef, #1A73E8, #1A73E8);
    cursor: pointer;
    margin-bottom: 10px;
    margin-top: 10px;
}

.menu-title-text {
    float: left;
    font-family: "Montserrat";
    font-weight: 800;
    font-size: 16px;
    padding: 12px;
    padding-top: 17px;
    padding-left: 15px;
    color: var(--is-active)
}

.menu-open {
    color: #1A73E8;
}

.menu-closed {
    color: white;
}

.menu-title-button {
    float: right;
    padding: 12px;
}

@media(min-width: 600px){
    .categories-in-field {
        padding: 50px;
    }
}

@media(max-width: 600px) {
    .categories-in-field {
        padding: 5px;
    }
}

.title-button {
    height: 30px;
    width: 30px;
    border: 1px;
    border-radius: 5px;
    margin-right: 20px;
    margin-left: 20px;
    font-weight: 900;
}

.title-close {
    padding-bottom: 10px;
    background-color: #1A73E8;
    border: solid white;
    color: white;
}

.title-open {
    padding-top: 7px;
    background-color: white;
    border: solid #1A73E8;
    color: #1A73E8;
}