.disabled-input {
    background-color: #E0E0E0;
}

.form-response {
    padding: 10px;
    font-family: 'Montserrat';
    font-weight: 700;
    text-align: center;
}

.modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
    border-radius: 15px;
    position: fixed;
    background-color: white;
    border: 0;
    z-index: 30;
    left: 50%;
    height: 435px;
}

@media (min-height:500px) {
    .modal {
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

@media (max-height:500px) {
    .modal {
        transform: translate(-50%, -0%);
    }
}

@media (min-width:460px) {
    .modal {
        width: 460px;
    }
}

@media (max-width:460px) {
    .modal {
        width: 100%;
    }
}

.btn {
    margin-top: 30px;
    background-color: #1A73E8;
    color: white;
    cursor: pointer;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 14px;
    min-height: 50px;
    max-width: 350px;
}

.btn-div {
    text-align: center;
}

.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.2;
    z-index: 25;
}

.hidden {
    display: none;
}