.field-text {
    padding-left: 4vw;
    padding-right: 4vw;
    font-weight: 400;
    font-size: 16px;
    color: #272727;
}

.field-button {
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 244px;
    height: 60px;
    border: 0px;
    z-index: 20;
    background-color: #F88560;
    box-shadow: 5px 5px 5px #fab391;
    border-radius: 10px;
}

.field-button-content {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.message-svg {
    background-image: url('../../media/messageIcon.svg');
    min-width: 56px;
    min-height: 33px;
    background-size: contain;
    background-repeat: no-repeat;
}
