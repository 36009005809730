.outer-box {
    height: 340px;
    width: 305px;
    background-image: url("../../media/packageOfferBackground.png");
    color: white;
    margin: 10px;
    padding: 30px;
    position: relative;
}

.description {
    font-weight: 400;
    font-size: 14px;
    color: white;
}

.old-cost {
    font-weight: 200;
    font-size: 20px;
    text-decoration: line-through;
    color: #66A2FF;
    position: absolute;
    bottom: 95px;
}

.new-cost {
    font-weight: 900;
    font-size: 32px;
    position: absolute;
    bottom: 55px;
    color: white;
}

.caveat {
    position: absolute;
    bottom: 40px;
    font-weight: 300;
    font-size: 12px;
    opacity: 0.7;
    color: white;
}

