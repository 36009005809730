.pros-list-container {
    display: flex;
}

.pro-container {
    width: 30%;
    text-align: center;
    padding: 20px;
    object-fit: contain;
}

@media(max-width: 500px){
    .pro-container {
        width: 30%;
        padding: 5px;
    }
}

.pro-image {
    max-width: 150px;
    margin: 5px;
    object-fit: contain;
}

.pro-text {
    max-width: 150px;
    display: inline-block;
    color: #5E5E5E;
    font-size: 16px;
    font-weight: 400;
}