.procedure-text {
    padding-left: 4vw;
    padding-right: 4vw;
    font-weight: 400;
    font-size: 16px;
    color: #272727;
}

.procedure {
    position: relative;
    border-radius: 15px;
    border: 15px;
    padding: 25px;
    margin: 30px 10px 30px 10px;
    box-shadow: 0px 6px 0.5em #e8f1fd;
}

.indication {
    background-color: #e5ffe1;
}

.contraindication {
    background-color: #FFE1E1;
}

.procedure-button {
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 244px;
    height: 60px;
    border: 0px;
    z-index: 20;
    background-color: #F88560;
    box-shadow: 5px 5px 5px #fab391;
    border-radius: 10px;
}