.sale-outer-box {
    background-color: #e5ffe1;
    position: relative;
    border-radius: 25px;
    border: 25px;
    margin-top: 20px;
    margin-bottom: 10px;
    box-shadow: 1px 5px 5px #e8f1fd;
}

@media (max-width:900px) {
    .sale-outer-box {
        display: grid;
        padding-bottom: 25px;
    }
}


@media (min-width:900px) {
    .sale-outer-box {
        display: flex;
        padding-right: 25px;
    }
}

.sale-offer-image {
    border-top-left-radius: 25px;
}

@media (max-width:900px) {
    .sale-offer-image {
        width: 100%;
        border-top-right-radius: 25px;
    }
}


@media (min-width:900px) {
    .sale-offer-image {
        height: 100%;
        border-bottom-left-radius: 25px;
    }
}

.sale-description {
    margin-bottom: 100px;
    color: #272727;
}

.data-sale-offer {
    padding: 30px;
    z-index: 10;
    background-color: #e5ffe1;
}

@media (max-width:900px) {
    .data-sale-offer {
        padding-bottom: 80px;
    }
}

.old-cost-sale {
    font-weight: 300;
    font-size: 20px;
    text-decoration: line-through;
    color: #66A2FF;
    position: absolute;
    bottom: 70px;
}

@media (max-width:900px) {
    .old-cost-sale {
        bottom: 117px;
    }
}


@media (min-width:900px) {
    .old-cost-sale {
        bottom: 70px;
    }
}

.new-cost-sale {
    font-weight: 900;
    font-size: 32px;
    color: #272727;
    position: absolute;
    
}

@media (max-width:900px) {
    .new-cost-sale {
        bottom: 82px;
    }
}


@media (min-width:900px) {
    .new-cost-sale {
        bottom: 30px;
    }
}

.sale-button {
    background: linear-gradient(0.25turn, #1A73E8, #5d9cef, #1A73E8, #1A73E8);
    border-radius: 10px;
    color: #E5FFE1;
    font-weight:700;
    font-family:"Montserrat";
    font-size:18px;
    width: 350px;
    height: 52px;
    border: 0px;
    z-index: 20;
}

@media (max-width:900px) {
    .sale-button-div {
        position: absolute;
        bottom: 20px;
        z-index: 20;
    }
}


@media (min-width:900px) {
    .sale-button {
        position: absolute;
        right: 35px;
        bottom: 40px;
    }
}

@media (max-width:900px) {
    .sale-button-div {
        width: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
    }
}
