.footer-container {
    background-color: #F8F8F8;
    border: 0;
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
}

@media(min-width:600px) {
    .footer-container {
        flex-direction: row;
        height: 410px;
    }
}

@media(max-width:600px) {
    .footer-container {
        padding-bottom: 20px;
        flex-direction: column;
    }
}

@media(min-width:600px) {
    .footer-padding {
        width: 100%;
        min-height: 410px;
    }
}

@media(max-width:600px) {
    .footer-padding {
        width: 100%;
        min-height: 1000px;
    }
}

@media(max-width:600px) {
    .mobile-padding {
        min-height: 50px;
    }
}

.footer-column{
    padding: 20px;
}

@media(min-width:600px) {
    .footer-column {
        width: 25%;
        padding-top: 80px;
    }
}

@media(max-width:600px) {

    #logo-column {
        order: 1;
    }

    #contacts-column{
        order: 2;
    }

    #info-column {
        order: 3;
    }

    #extra-column {
        order: 4;
    }
}

.footer-header {
    color: #1A73E8;
}

@media(min-width:600px) {
    .footer-header {
        text-align: left;
        padding-left: 10px;
    }
}

.footer-logo {
    background-image: url("../../media/veritas-logo-ua-full.svg");
    padding-top: 40px;
    height: 81px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.footer-workhours {
    display: flex;
    justify-content: center;
}

.footer-workhours-day {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 11px;
    color: #3A3A3A;
}

.footer-workhours-time {
    font-weight: 700;
    font-size: 11px;
    font-family: "Montserrat";
    color: #3A3A3A;
}

.footer-social-media{
    text-align: center;
    padding: 20px;
}

.footer-link {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 12px;
    color: #272727;
    padding: 10px;
    text-decoration: underline;
}

@media(max-width:600px) {
    .footer-link {
        text-align: center;
    }
}

.address-symbol {
    background-image: url("../../media/mapSymbol.svg");
    height: 30px;
    width: 34px;
    min-width: 34px;
    margin: 5px;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: contain;
}

.footer-contacts-data {
    display: flex;
}


@media(max-width:600px) {
    .footer-contacts-data {
        justify-content: center;
    }
}

.footer-contacts-text {
    font-size: 12px;
    font-weight: 400;
    padding: 10px;
    padding-top: 0px;
    width: calc(100% - 45px);
}

@media(max-width:600px) {
    .footer-contacts-text {
        max-width: 180px;
    }
}

.phone2-symbol {
    background-image: url("../../media/phoneSymbol2.svg");
    height: 30px;
    width: 34px;
    margin: 5px;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: contain;
}