::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #e8f1fd;
    background-color: #e8f1fd;
    color: #e8f1fd;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb {
    background: #1A73E8;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    cursor: grab;
}

.show-index-button {
    width: 350px;
    height: 50px;
    border-radius: 10px;
    border: 1px solid #1A73E8;
    color: #1A73E8;
    font-family: "Montserrat";
    font-weight: 700;
    font-size: 14px;
    background-color: white;
    margin: 10px;
}

.carousel{
    padding: 0px;
    overflow-x: hidden;
}

.review-container {
    width: 325px;
    height: 252px;
    font-size: 14px;
    box-shadow: 3px 3px 5px 5px #e8f1fd;
    margin: 20px;
    border: 10px solid white;
    border-radius: 10px;
    padding: 5px;
}

.review-header{
    height: 44px;
    display: flex;
    align-items: center;
}

.review-image {
    height: 44px;
    margin-right: 20px;
}

.review-rating {
    color: #FF8F33;
    font-size: 18px;
    margin: 5px;
    margin-left: 0px;
}

.review-text {
    max-height: 150px;
    max-width: 100%;
    margin-right: 10px;
    overflow-y: auto;
    cursor: auto;
    white-space: pre-wrap;
}

.swiper-navigation-btn{
    border: 0;
    background-color: white;
    font-size: 21px;
    margin: 10px;
}

@media(min-width: 400px){
    .swiper-container{
        width: 100%;
    }
}

@media(max-width: 400px) {
    .swiper-container {
        width: 100vw;
        margin: -10px;
    }
}

.index-header {
    background-image: url("../../media/indexHeaderBackground.png");
    background-size: cover;
    width: 100%;
    height: 90vh;
    margin: 0px;
    padding: 0px;
    border: 0px;
    z-index: 20;
    position: absolute;
    top: 0;
}

.index-header-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    width: 40%;
    height: 80%;
}

@media(max-width: 700px) {
    .index-header-title {
        justify-content: center;
    }
}

.index-header-image {
    position: absolute;
    bottom: 35px;
    right: 5%;
    width: 50%;
}

.index-header-imagesrc {
    width: 100%;
    margin: 0px;
    padding: 0px;
    border: 0px;
}

.index-header-title {
    color: white;
    font-family: "Montserrat";
    font-size: 40px;
    font-weight: 700;
    text-align: left;
    
}

@media(min-width: 700px){
    .index-header-title{
    }
}

.index-header-plaintext {
    color: white;
}

@media(max-width: 700px) {
    .index-header-plaintext {
        padding-top: 20px;
        padding-bottom: 1%;
    }
}

@media(min-width: 700px) {
    .index-header-plaintext {
        padding-top: 20px;
        padding-bottom: 5%;
    }
}

.index-header-button {
    color: white;
    border: 0;
    border-radius: 10px;
    background-color: #F88560;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 700;
}

@media(min-width:700px) {
    .index-header-button {
        width: 350px;
        height: 52px;
    }
}

@media(max-width:700px){
    .index-header-button {
        width: 90%;
        height: 50px;
    }
}

.index-contacts-block {
    font-size: 14px;
}

@media(min-width: 700px){
    .index-contacts-block {
        position: absolute;
        left: 0;
        right: 0;
        top: -65px;
        display: flex;
        justify-content: space-between;
    }
}

@media(max-width: 700px){
    .index-contacts-block {
        justify-content: center;
    }
}

.index-contacts-container {
    height: 100px;
    background-color: white;
    border-radius: 10px;
    border: 0;
    z-index: 21;
    display: flex;
}

@media(min-width:700px){
    .index-contacts-container {
        width: 350px;
        justify-content: center;
    }
}

@media(max-width: 700px){
    .index-contacts-container {
        width: calc(90% - 15px);
        box-shadow: 1px 1px 3px 3px #e8f1fd;
        margin: 10px;
        justify-content: flex-start;
        padding-left: 30px;
    }
}

.shadow-index-contacts-container {
    height: 104px;
    width: 354px;
    background-color: #e8f1fd;
    border-radius: 10px;
    border: 0;
    z-index: -1;
    filter: blur(4px);
}

.index-contacts-icon {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 70px;
}

.index-contacts-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media{
    .index-contacts-text {
        margin-left: 20px;
    }
}

.borderless{
    margin: 0;
    border: 0;
    padding: 0;
}