.road-container
{
    display: flex;
    width: 100%;
}

.road-data {
    padding: 20px;
    padding-top: 0;
    padding-left: 5px;
}