.header-desktop-container {
    height: 80px;
    border: 0;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    width: 90%;
}

@media(min-width: 1000px) {
    .header-desktop-container {
        width: 84%;
    }
}

.desktop-logo {
    min-height: 58px;
    min-width: 130px;

}

.header-logo {
    background-image: url("../../media/veritas-logo-ua.svg");
    background-repeat: no-repeat;
    filter: brightness(0) invert(1);
    background-size: contain;
    background-position: top 0px left 0px;
}

.header-link {
    color: white;
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 16px;
    padding: 5px;
    padding-top: 0px;
    text-align: center;
    align-items: center;
    display: flex;
}

@media(max-width: 700px) {
    .header-link {
        width: 100%;
        height: 30px;
    }
}

.header-social-media {
    padding-left: 20px;
    padding-top: 0px;
    width: 100px;
    display: flex;
}

@media(max-width: 700px) {
    .header-social-media {
        width: 100%;
        padding: 0px;
        height: 30px;
        justify-content: center;
    }
}

.header-desktop-menu {
    display: flex;
    width: 100%;
    justify-content: space-between;
}



.mobile-desktop-container {
    height: 70px;
    border: 0;
    margin: 0;
    display: flex;
    position: absolute;
    top: 0;
    padding-left: 10%;
    padding-right: 7%;
    width: 83%;
    align-items: center;
}

.mobile-logo {
    height: 50px;
    width: 200px;
}

.mobile-menu-icon{
    padding-left: 30px;
    padding-right: 30px;
}

.open-header-menu {
    background-color: transparent;
    border: 0;
    color: white;
    font-family: "Montserrat";
    font-weight: 900;
    font-size: 50px;
}

.hidden {
    display: none;
}

.mobile-menu-container {
    z-index: 50;
    background-image: url("../../media/mobileMenuBackground.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
}

.header-mobile-menu {
    width: 100%;
    position: absolute;
    top: 70px;
    height: calc(100% - 250px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.menu-schedule-button {
    border: 0;
    z-index: 22;
    color: white;
    background-color: #F88560;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 700;
    border-radius: 10px;
    width: 300px;
    height: 60px;
    
}

.menu-schedule-div {
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: 40px;
}

.with-bg {
    background: linear-gradient(0.25turn, #1A73E8, #5d9cef, #1A73E8, #1A73E8);
}