.doctor-container-full {
    background-color: white;
    border-radius: 25px;
    border: 0;
    box-shadow: 3px 3px 5px 5px #e8f1fd;
    padding: 25px;
    margin: 30px;
    position: relative;
}

@media (min-width: 800px){
    .doctor-container-full{
        display: flex;
    }
}

@media (max-width: 800px) {
    .doctor-container-full {
        justify-content: center;
        text-align: center;
    }
}

.doctor-image {
    height: 210px;
    border-radius: 50%;
}

.doctor-field {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    color: #1A73E8;
    margin-bottom: 40px;
}

.doctor-presentation-div {
    text-align: center;
    padding: 10px;
    justify-content: center;
}

@media (max-width: 800px) {
    .doctor-presentation-div {
        padding-bottom: 0px;
    }
}

@media (min-width: 800px) {
    .doctor-data-h {
        text-align: left;
    }
}

.doctor-data {
    padding: 30px;
}

@media (min-width: 800px) {
    .doctor-data {
        padding-left: 100px;
    }
}

@media (max-width: 800px) {
    .doctor-data {
        padding-top: 0px;
    }
}

.doctor-button {
    width: 370px;
    max-width: 100%;
    height: 52px;
    border: 0px;
    z-index: 20;
    border-radius: 10px;
    background: linear-gradient(0.25turn, #1A73E8, #5d9cef, #1A73E8, #1A73E8);
    color: white;
}

@media (min-width: 800px) {
    .doctor-button-div {
        position: absolute;
        top: 15px;
        right: 10px;
        z-index: 20;
    }
}

@media (max-width:800px) {
    .doctor-button-div {
        width: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
    }
}



