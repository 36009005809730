.preview {
    background-color: white;
    border-radius: 10px;
    border: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    box-shadow: 3px 3px 5px 5px #e8f1fd;
    cursor: pointer;
    padding: 15px;
}

@media(min-width: 500px) {
    .preview {
        height: 100px;
        width: 255px;
        margin: 15px;
    }
}

@media(max-width: 500px){
    .preview {
        max-height: 100px;
        width: 100%;
        margin: 10px;
    }
}

.preview-list {
    display: flex;
    flex-wrap: wrap;
}

@media(max-width: 500px) {
    .preview-list {
        width: calc(100% - 50px);
        padding: 0;
        margin: 0;
        flex-direction: column;
    }
}