.blog-item-container {
    background-color: white;
    position: relative;
    border-radius: 15px;
    border: 15px;
    margin-top: 20px;
    margin-bottom: 10px;
    box-shadow: 3px 3px 5px 5px #e8f1fd;
    max-width: 100%;
    min-width: 100px;
}

@media (max-width:800px) {
    .blog-item-container {
        display: grid;
        padding-bottom: 25px;
    }
}

@media (min-width:800px) {
    .blog-item-container {
        display: flex;
        padding-right: 25px;
        max-height: 300px;

    }
}

.blog-preview-image {
    border-top-left-radius: 15px;
}

@media (max-width: 800px) {
    .blog-preview-image {
        width: 100%;
        border-top-right-radius: 15px;
    }
}


@media (min-width: 800px) {
    .blog-preview-image {
        height: 100%;
        border-bottom-left-radius: 15px;
    }
}

.data-blog-item {
    padding: 30px;
    z-index: 10;
    overflow: hidden;
    min-width: 100px;
}

.blog-preview-text{
    overflow: hidden;
}

@media (min-width: 800px){
    .blog-preview-text {
        height: calc(100% - 100px);
    }
}

@media (max-width: 800px) {
    .blog-preview-text {
        max-height: 300px;
        min-width: 100px;
    }
}

@media (max-width:800px) {
    .blog-button-div {
        width: 100%;
        z-index: 20;
        padding-right: 30px;
        padding-left: 30px;
    }
}

.blog-preview-button {
    background: linear-gradient(0.25turn, #1A73E8, #5d9cef, #1A73E8, #1A73E8);
    border-radius: 10px;
    color: white;
    font-weight: 700;
    font-family: "Montserrat";
    font-size: 18px;
    width: 350px;
    height: 52px;
    border: 0px;
    z-index: 20;
}

@media (min-width: 800px) {
    .blog-preview-button {
        position: absolute;
        right: 25px;
        bottom: 20px;
    }
}

@media (max-width: 800px) {
    .blog-preview-button {
        width: calc(100% - 60px) ;
    }
}

.blog-navigation-container{
    display: flex;
    width: 100%;
    padding: 10px;
    font-weight: 700;
}