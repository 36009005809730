body {
    margin: 0;
    padding: 0;
}

#root {
    margin: 0;
    padding: 0;
    position: relative;
}

button {
    cursor: pointer;
}

h1 {
    text-align: center;
    margin-top: 0px;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 20px;
}

h2 {
    text-align: center;
    font-family: 'Montserrat';
    font-size: 18px;
    font-weight: 700;
}

div {
    font-family: 'Open Sans';
    color: #272727;
}

li {
    margin: 10px 0;
}

h4 {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 16px;
    text-align: center;
}

label {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 12px;
    margin-left: 20px;
}

input {
    margin: 5px 10px 5px 10px;
    border-radius: 8px;
    border: 2px solid #1A73E8;
    height: 30px;
    width: 90%;
    padding: 5px;
}

hr {
    height: 1px;
    border: 0;
}

@media (min-width:800px) {
    .display-on-hover {
        display: none;
    }
}

.hoverable:hover .display-on-hover {
    display: inline-block;
}

a:link {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

a:active {
    text-decoration: none;
}

.frame {
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 20px;
}

@media(min-width: 600px) {
    .frame {
        padding-left: 5%;
        padding-right: 5%;
    }
}

@media(min-width: 1000px){
    .frame {
        padding-left: 8%;
        padding-right: 8%;
    }
}

.last {
    padding-bottom: 40px;
}