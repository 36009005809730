.doctor-shadow {
    -webkit-filter: blur(3px);
    -moz-filter: blur(3px);
    -o-filter: blur(3px);
    -ms-filter: blur(3px);
    backdrop-filter: blur(3px);
    background: linear-gradient(white, #e8f1fd);
    z-index: -1;
    border-radius: 10px;
    border: 0;
    position: absolute;
    bottom: -5px;
    height: calc(100% + 10px);
    left: -5px;
    width: calc(100% + 10px);
}

.doctor-container-preview {
    position: relative;
    cursor: pointer;
    margin: 20px;
    height: 505px;
    width: 350px;
}

.doctor-container-data {
    border: 0;
    padding: 25px;
    background-color: white;
    border-radius: 10px;
    z-index: 10;
    text-align: center;
    z-index: 5;
    height: 450px;
    width: 300px;
}

.doctor-image-preview {
    height: 150px;
    border-radius: 50%;
}

.doctor-preview-h {

}

.doctor-hidden-button {
    border: 0;
    margin: 0;
    padding: 0;
    background-color: transparent;
}