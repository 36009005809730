.path {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 14px;
    color: #303436;
    text-decoration: none;
}

.header-picture{
    width: 100%;
}

@media(min-width: 700px){
    .header-padding{
        width: 100%;
        min-height: 80px;
    }
}

@media(max-width: 700px) {
    .header-padding {
        width: 100%;
        min-height: 70px;
    }
}