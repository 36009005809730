.main-header {
    font-weight: 800;
    font-size: 30px;
    margin: 20px;
    margin-bottom: 30px;
    margin-top: 70px;
    white-space: pre-line;
}

@media(max-width: 700px){
    .main-header {
        font-weight: 700;
        font-size: 20px;
        margin: 10px;
        margin-bottom: 20px;
        margin-top: 50px;
    }
}

.offers-button {
    width: 350px;
    height: 52px;
    background-color: #1A73E8;
    border: 0;
    border-radius: 10px;
    color: white;
    margin: 20px;
}

@media(max-width: 500px){
    .offers-button {
        margin-left: 0px;
        margin-right: 0px;
    }
}

.h2-button{
    font-size: 14px;
}

@media(min-width: 600px){
    .contacts-container {
        display: inline-block;
        width: 100%;
    }
    .contacts-photo-container{
        float: left;
        width: 45%;
    }
    .contacts-data-container {
        float: right;
        padding-left: 30px;
        width: 50%;
    }
}

@media(max-width: 600px) {
    .contacts-container {
        justify-content: center;
        text-align: center;
    }
}

.contacts-photo {
    border: 0;
    margin: 15px;
    position: relative;
    border-radius: 15px;
    width: calc(100% - 30px);
    aspect-ratio: 4 / 3;
}

.veritas-picture {
    background-image: url("../../media/veritasPhoto.png");
    background-size: contain;
    aspect-ratio: 1 / 1;
}

.contacts-section{
    padding-bottom: 40px;
}

.symbol {
    height: 20px;
    width: 19px;
    padding: 10px;
    background-repeat: no-repeat;
    background-position: top;
}

.svg-symbol {
    height: 30px;
    width: 22px;
    margin: 10px;
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100%;
}

.address-image {
    background-image: url("../../media/mapSymbol.svg");
}

.phone1 {
    background-image: url("../../media/phoneSymbol1.svg");
}

.email {
    background-image: url("../../media/emailSymbol.svg");
}

.contacts-data {
    font-size: 16px;
    text-align: left;
}

.workhours-day{
    font-weight: 700;
    font-size: 16px;
    width: 100px;
    padding-left: 10px;
}

.workhours-time{

}

.general-schedule-button {
    background: linear-gradient(0.25turn, #1A73E8, #5d9cef, #1A73E8, #1A73E8);
    border-radius: 10px;
    color: white;
    font-weight: 700;
    font-family: "Montserrat";
    font-size: 14px;
    width: 350px;
    height: 52px;
    border: 0px;
    z-index: 20;
    margin-top: 15px;
}

.timer-number {
    font-size: 62px;
    font-family: "Montserrat";
    font-weight: 700;
    color: #303436;
    display: flex;
    align-items: flex-end;
}

.timer-desc {
    font-size: 21px;
    font-family: "Montserrat";
    font-weight: 400;
    color: #4B4B4BBF;
    opacity: 0.75;
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
}

@media(max-width: 700px){
    .timer-number {
        font-size: 26px;
    }

    .timer-desc {
        font-size: 9px;
        margin-bottom: 2px;
    }
}

.index-content-header {
    margin-top: 30px;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.important-caveat {
    border-radius: 10px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-top: 30px;
    margin-bottom: 30px;
    box-shadow: 0px 6px 0.5em #e8f1fd;
    background-color: #e5ffe1;
    font-weight: 700;
    font-size: 16px;
}

.contacts-data-outer {
    display: flex;
}

.contacts-text {
    font-size: 12px;
    font-weight: 400;
    padding: 10px;
    padding-top: 0px;
    text-align: left;
}